#client-portal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  top: 56px;
  width: 100vw;
  min-width: 100vw;
  min-height: calc(100vh - 162px);
  height: auto;
  padding: 0 !important;
}
