.fileDetailsBtn#fileDetailsBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}

.fileDetailsBtn#fileDetailsBtn:hover {
  background-color: transparent !important;
}

.savedProjectBtn#savedProjectBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.savedProjectBtn#savedProjectBtn:hover {
  background-color: transparent !important;
}

.savedProjectIcon {
  fill: transparent;
  stroke: var(--argus-white);
  stroke-width: 2px;
  height: 25px;
  width: 25px;
}

.savedProjectIcon.filled {
  transition: fill 0.3s ease-in-out;
  fill: var(--btn-red);
  stroke: var(--btn-red);
}

.savedProjectIcon.outlined {
  transition: fill 0.3s ease-in-out;
  fill: transparent;
  stroke: var(--btn-red);
  /* stroke: var(--argus-white); */
}

.savedProjectBtn#savedProjectBtn:hover .savedProjectIcon.outlined {
  fill: var(--btn-red);
  stroke: var(--btn-red);
}

.savedProjectBtn#savedProjectBtn:hover .savedProjectIcon.filled {
  fill: transparent;
  stroke: var(--btn-red);
  /* stroke: var(--argus-white); */
}

#folderIcon {
  color: #f3dda6;
  height: 40px;
  width: 40px;
  filter: drop-shadow(0px 1px 1px #0b2e439b);
}

#editButton.editButton {
  background-color: var(--argus-white) !important;
  color: rgb(46, 179, 227) !important;
  height: 30px;
  width: 70px !important;
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  transition: all 500ms ease 0s !important;
}

#editButton.editButton:hover {
  background-color: rgb(52, 166, 208) !important;
  color: var(--argus-white) !important;
}

#editButton.editButton:disabled {
  background-color: var(--disabled-btn-bg) !important;
  color: var(--disabled-btn-color) !important;
  cursor: not-allowed !important;
}

#inProgressButton.inProgressButton {
  color: rgb(230, 114, 13);
  background-color: var(--argus-white);
  height: 30px;
  width: 70px !important;
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  transition: all 500ms ease 0s !important;
}

#inProgressButton.inProgressButton:hover {
  background-color: rgb(230, 114, 13);
  color: var(--argus-white);
}

#archiveButton.archiveButton {
  color: #4d535a;
  background-color: var(--argus-white);
  height: 30px;
  width: 72px !important;
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  letter-spacing: 0.1px;
  transition: all 500ms ease 0s !important;
}

#archiveButton.archiveButton:hover {
  background-color: #4d535a;
  color: var(--argus-white);
}

#pendingButton.pendingButton {
  color: rgb(125, 125, 125);
  background-color: var(--argus-white);
  height: 30px;
  width: 62px;
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  letter-spacing: 0.1px;
  transition: all 500ms ease 0s !important;
}

#pendingButton.pendingButton:hover {
  background-color: rgb(145, 145, 145);
  color: #eaeef7;
}

#reviseButton.reviseButton {
  color: rgb(228, 61, 61);
  background-color: var(--argus-white);
  height: 30px;
  width: 72px !important;
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  letter-spacing: 0.1px;
  transition: all 500ms ease 0s !important;
}

#reviseButton.reviseButton:hover {
  background-color: rgb(204, 71, 71);
  color: var(--argus-white);
}

#downloadButton.downloadButton {
  color: rgb(46, 187, 31);
  background-color: var(--argus-white);
  height: 32px;
  width: 100px !important;
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  letter-spacing: 0.1px;
  transition: all 500ms ease 0s !important;
}

#downloadButton.downloadButton:hover {
  background-color: rgb(46, 187, 31);
  color: var(--argus-white);
}

.requestTypeCells span {
  text-shadow: 0px 1px 1px rgb(92, 92, 92);
  font-weight: var(--default-font-weight);
  text-align: center;
  position: relative;
  display: flex;
  align-self: center;
  justify-self: center;
}

.projectTypeCells span {
  text-align: center;
  font-weight: var(--default-font-weight);
  text-shadow: 0px 1px 1px rgb(92, 92, 92);
}

.servicesButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  padding: 0px;
  margin: 0px;
}

.boolCol.requested .servicesButton:hover {
  background-color: transparent;
}

.boolCol.requested .boolIcon {
  color: #1ce92d !important;
  height: 38px;
  width: 38px;
  stroke: #1fea30;
  filter: drop-shadow(0px 1px 1px #006e0b86);
  align-self: center;
  pointer-events: none;
}

.boolCol.notRequested .boolIcon {
  height: 38px;
  width: 38px;
  fill: #ded93d !important;
  stroke: #ded93d !important;
}

.boolCol.unknown .boolIcon#hrIcon {
  fill: #ded93d !important;
  stroke: #ded93d !important;
  stroke-width: 2px;
  -webkit-text-stroke-width: 4px;
  height: 30px;
  width: 30px;
}

.tooltipPopper {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 0px;
}

.tooltip {
  background-color: rgba(75, 75, 75, 0.936);
  color: rgb(228, 228, 228);
  font-weight: 500;
  font-size: 17px;
  max-width: 415px !important;
  min-width: 350px !important;
  text-align: center;
  align-self: center;
  padding: 10px 8px;
}

.arrow {
  color: rgba(58, 58, 58, 0.8);
}

.columnSeparator {
  display: none !important;
}

.sortIcon {
  color: white;
  opacity: 1;
  visibility: visible !important;
  stroke: white;
  max-width: 20px !important;
  width: min-content !important;
  z-index: 99999 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.columnHeader:target(.columnHeaderSorted) .sortIcon {
  visibility: visible !important;
  opacity: 1 !important;
  stroke: white;
}

.columnHeader:not(.columnHeaderSorted) .sortIcon {
  visibility: visible !important;
  opacity: 0.8 !important;
  stroke: rgb(239, 239, 239);
}

.columnHeaderSortable:hover .sortIcon {
  visibility: visible !important;
  opacity: 1 !important;
  stroke: white;
  color: white;
}

.iconButtonContainer {
  display: flex;
  justify-content: center !important;
  align-items: center;
  visibility: visible !important;
  opacity: 1 !important;
  width: min-content !important;
  margin: 0 !important;
  padding: 0 !important;
}

.requestPaper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
}

.requestPaper .requestTitle {
  color: rgb(33, 131, 181);
  font-size: 18px;
  margin: 0px;
  padding: 0px;
  width: 273px;
  background-color: black;
  text-align: center;
}

.requestListItem {
  font-size: 18px;
  color: rgb(65, 65, 65);
}

.noRowsOverlayClient {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 100%;
}

.noRowsOverlayTextClient {
  color: var(--argus-white);
  font-size: 1.6em;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  padding: 0;
  margin: 0;
  align-self: center;
  width: fit-content;
}

#createRequestButton.noRowsOverlayButtonClient {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 190px;
  height: 45px;
  background-color: var(--btn-green);
  border-radius: 8px;
  padding: 0;
}

#createRequestButton.noRowsOverlayButtonClient:hover {
  cursor: pointer;
  background-color: var(--btn-green-hover);
}

.noRowsOverlayButtonIconClient {
  stroke: var(--btn-white-text);
  color: var(--btn-white-text);
  padding: 0;
  margin: 0;
}

.noRowsOverlayButtonTextClient {
  font-size: 18px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  color: var(--btn-white-text);
  line-height: 1;
  letter-spacing: 0.1px;
  margin: 0;
}

@media screen and (width < 1025px) {
  #editButton.editButton {
    height: 22px;
    min-width: 48px !important;
    max-width: 48px !important;
    font-size: 9px;
  }

  #inProgressButton.inProgressButton,
  #archiveButton.archiveButton,
  #pendingButton.pendingButton,
  #reviseButton.reviseButton {
    height: 22px;
    min-width: 55px !important;
    max-width: 55px !important;
    font-size: 9px;
  }

  #downloadButton.downloadButton {
    height: 22px;
    max-width: 75px !important;
    min-width: 75px !important;
    font-size: 9px;
  }
}

@media screen and (1025px <= width < 1450px) {
  #editButton.editButton {
    height: 22px;
    min-width: 50px !important;
    max-width: 50px !important;
    font-size: 10px;
  }

  #inProgressButton.inProgressButton,
  #archiveButton.archiveButton,
  #pendingButton.pendingButton,
  #reviseButton.reviseButton {
    height: 22px;
    min-width: 60px !important;
    max-width: 60px !important;
    font-size: 10px;
  }

  #downloadButton.downloadButton {
    height: 22px;
    max-width: 80px !important;
    min-width: 80px !important;
    font-size: 10px;
  }

  .fileDetailsBtn#fileDetailsBtn {
    height: 35px;
    width: 35px;
  }

  #folderIcon {
    height: 30px;
    width: 30px;
  }
  .savedProjectBtn#savedProjectBtn {
    width: 30px !important;
    height: 30px !important;
  }

  .savedProjectIcon {
    height: 22px !important;
    width: 22px !important;
  }
}

@media screen and (1450px <= width < 1630px) {
  #editButton.editButton,
  #inProgressButton.inProgressButton,
  #archiveButton.archiveButton,
  #reviseButton.reviseButton {
    height: 25px;
    width: 60px !important;
    font-size: 11px;
  }

  #pendingButton.pendingButton {
    height: 25px;
    width: 70px !important;
    font-size: 11px;
  }

  #downloadButton.downloadButton {
    height: 26px;
    width: 90px !important;
    font-size: 11px !important;
  }

  .fileDetailsBtn#fileDetailsBtn {
    height: 35px;
    width: 35px;
  }

  #folderIcon {
    max-height: 30px;
    max-width: 30px;
  }
}

@media screen and (1630px <= width < 1850px) {
  #editButton.editButton,
  #inProgressButton.inProgressButton,
  #archiveButton.archiveButton,
  #reviseButton.reviseButton {
    height: 25px;
    min-width: 60px !important;
    max-width: 60px !important;
    font-size: 11px;
  }

  #pendingButton.pendingButton {
    height: 25px;
    width: 65px !important;
    font-size: 11px;
  }

  #downloadButton.downloadButton {
    height: 25px;
    width: 90px !important;
    font-size: 11px !important;
  }

  .fileDetailsBtn#fileDetailsBtn {
    height: 45px;
    width: 45px;
  }

  #folderIcon {
    max-height: 30px;
    max-width: 30px;
  }

  .savedProjectBtn#savedProjectBtn {
    width: 30px !important;
    height: 30px !important;
  }

  .savedProjectIcon {
    height: 25px !important;
    width: 25px !important;
  }
}

@media screen and (1850px <= width) {
  #editButton.editButton,
  #inProgressButton.inProgressButton,
  #archiveButton.archiveButton,
  #reviseButton.reviseButton {
    height: 25px;
    min-width: 55px !important;
    font-size: 11px;
    max-width: 60px !important;
  }

  #pendingButton.pendingButton {
    height: 25px;
    min-width: 55px !important;
    max-width: 60px !important;
    font-size: 11px;
  }

  #downloadButton.downloadButton {
    height: 25px;
    width: 90px !important;
    font-size: 11px !important;
  }

  .fileDetailsBtn#fileDetailsBtn {
    height: 50px;
    width: 50px;
  }

  #folderIcon {
    max-height: 32px;
    max-width: 32px;
  }
}
